import devtools from 'devtools-detect';

((global) => {
    const waitForEvent = (eventType: string, eventSubType: string) =>
        new Promise((resolve) => {
            global.addEventListener(eventType, function listener(evt) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (evt?.detail?.type === eventSubType) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    resolve(evt?.detail?.data);
                    global.removeEventListener(eventType, listener);
                }
            });
        });
    const hasMfeDevtools = function () {
        const devtoolsFlag = global.sessionStorage.getItem('__GSCOPE_MFE_DEVTOOLS_AVAILABLE__');
        if (!devtoolsFlag) {
            return false;
        }
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return !!CryptoJS.AES.decrypt(devtoolsFlag, __GSCOPE_MFE_KEY__).toString(CryptoJS.enc.Utf8);
        } catch (error) {
            return false;
        }
    };
    const mfeDevtools = devtools.isOpen && hasMfeDevtools();
    const importMapOverridesPromise = mfeDevtools
        ? waitForEvent('@gscope-mfe/devtools:content', 'IMPORT_MAP_OVERRIDES')
        : Promise.resolve({});
    const globalSystem = global.System;
    const systemJSPrototype = globalSystem.constructor.prototype;
    const REGISTRY: IRegistry = {};
    const onLoadSubscribers: { [k: string]: (err?: Error, isErrSource?: boolean) => void } = {};
    const DEPENDENCY_PROMISES: { [k: string]: Promise<ILeanModule> } = {};

    interface IModule {
        id: string;
        _deps: string[];
        deps?: IModule[];
    }
    interface ILeanModule {
        id: string;
        deps: ILeanModule[];
    }

    interface IRegistry {
        [k: string]: IModule;
    }
    function cloneModuleTree(module: IModule): ILeanModule {
        return { id: module.id, deps: module.deps.map((d) => cloneModuleTree(d)) };
    }
    function constructModuleTree(id: string): IModule {
        const module = REGISTRY[id];
        const { _deps, deps } = module;
        if (deps) {
            return module;
        }
        return Object.assign(module, {
            deps: _deps.map((d) => constructModuleTree(d)),
        });
    }
    systemJSPrototype.getModuleTree = (id: string): Promise<ILeanModule> => {
        const resolvedId = systemJSPrototype.resolve.call(this, id);
        return (
            DEPENDENCY_PROMISES[resolvedId] ||
            (DEPENDENCY_PROMISES[resolvedId] = new Promise((resolve, reject) => {
                if (REGISTRY[resolvedId]) {
                    return resolve(cloneModuleTree(REGISTRY[resolvedId]));
                }
                onLoadSubscribers[resolvedId] = (err?: Error, isErrSource?: boolean) => {
                    if (err) {
                        return reject({ err, isErrSource });
                    }
                    resolve(cloneModuleTree(REGISTRY[resolvedId]));
                };
            }))
        );
    };
    systemJSPrototype.onload = (err: Error, id: string, _deps: string[], isErrSource: boolean): void => {
        if (err) {
            if (onLoadSubscribers[id]) {
                onLoadSubscribers[id](err, isErrSource);
            }
            return;
        }
        REGISTRY[id] = {
            id,
            _deps,
        };
        REGISTRY[id] = constructModuleTree(id);
        if (onLoadSubscribers[id]) {
            onLoadSubscribers[id]();
        }
    };
    const _fetch = systemJSPrototype.fetch;
    systemJSPrototype.fetch = async function (url: string, options) {
        if (options && options.passThrough) {
            try {
                const importMapOverridesJson = await importMapOverridesPromise;
                console.log('importmap-overrides:', importMapOverridesJson);
                if (!importMapOverridesJson || !Object.keys(importMapOverridesJson).length) {
                    throw 'Overrides unavailable';
                }
                const importMapOverrides = encodeURIComponent(JSON.stringify(importMapOverridesJson));
                const r = await _fetch(`${url}&importMapOverrides=${importMapOverrides}`, options);
                const overridedImportMap = await r.clone().json();
                const missingModules = Object.keys(importMapOverridesJson).filter(
                    (m) => !overridedImportMap.imports[m],
                );
                if (missingModules.length) {
                    console.error(
                        "Following modules aren't available. Please override with the correct version:\n",
                        missingModules.map((m) => `${m}: ${importMapOverridesJson[m]}`).join('\n'),
                    );
                    console.error(`Please clear them from Gscope MFE Devtools`);
                    console.error('And reload the page');
                }
                return r;
            } catch (error) {
                console.log(error);
                console.log('Gscope MFE Devtools or importmap override not found!');
                return _fetch(url, options);
            }
        }
        return _fetch(url, options);
    };
})(typeof self !== 'undefined' ? self : global);
